.walkscore {

  a:hover {
    color: #1e7ebe;
  }

  .scoretext {
    position: relative;
    color: #1e7ebe;
    margin-left: 7px;
    text-decoration: none;
    font-weight: bold;
  }

  .down3 {
    top: 3px;
  }

  #ws_info {
    position: relative;
    margin-left: 4px;
    top: -6px;
  }
}