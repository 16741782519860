@import "_fonts.scss";
@import "./quillpro/quillpro-mixins";

$primary-color: #4C158A;
$secondary-color: #f2fcfb;
$black: #000000;
$white: #ffffff;
$orange: #fa861c;
$orange-fade: #fef9f2;
$theme-text-color: #021E40;
$gray-fade: #efefef;
$gray-dark: #4f5b60;

/* font family*/
$light-font: 'Avenir-light', sans-serif;
$regular-font: 'Avenir-regular', sans-serif;
$bold-font: 'Avenir-bold', sans-serif;
$black-font:'Avenir-black', sans-serif;

$qp-border-radius-large: .4167rem;
$qp-border-radius-small: .20835rem;
$qp-border-radius-inputs: 5px;

$color-leasera-complement-primary:      #D7516F;
$color-leasera-complement-bright:       #f0718f;
$color-leasera-complement-light:        #E45676;
$color-leasera-complement-medium:       #D75172;
$color-leasera-complement-heavy:        #8A3447;
$color-leasera-complement-dark:         #31131A;