@import "../../styles/quillpro/quillpro-variables";
@import "../../styles/_variables";
@import "../../SiteShared.scss";



.semanticConfirm {
  .content {
    padding-left:15px !important;

  }
}
.signin-container {
    min-height: 100vh;
    display: flex;
}
  
  .signup-section {
    display: flex;
    align-items: center;
    min-height: 100%;
    width: 100%;
    .signup-left {
      width: 450px;
      height: 100%;
      position: fixed;
      justify-content: center;
      flex-direction: column;
      color: $white;
      top: 0px;
      bottom: 0px;
      left: 0px;
      @media (max-width: 992px){
        width: 50%;
      }
      @media (max-width: 768px){
        width: 100%;
        height: 90px;
      z-index: 9;
      }
      .brand-image {
        z-index: 2;
        position: relative;
        margin-bottom: 30px;
        @media (max-width: 768px){
          margin-bottom: 0px;
        }
        img{
          max-width: 175px;
        }
      }
      .text {
        z-index: 2;
        position: relative;
        color: #ffffff;
        @media (max-width: 768px){
         display: none;
        }
        .small {
          opacity: 0.7;
          font-size: 14px;
          line-height: 2.14;
        }
        .big {
          font-size: 25px;
          font-weight: 800;
          line-height: 1.44;
          margin-bottom: 15px;
        }
      }
      .carousel,
      .carousel-inner,
      .carousel-item {
        height: 100%;
      }
      .content-wrap {
        display: flex;
        flex-direction: column;
        padding: 0px 100px;
        justify-content: center;
        height: 100%;
        @media (max-width: 768px){
          padding: 0px 20px;
        }
      }
      .carousel-indicators {
        left: 21%;
        bottom: 170px;
        transform: translate(0%, -20px);
        @media (max-height: 738px){
          bottom: 14%;
        }
        @media (max-height: 594px){
          bottom: 4%;
        }
        @media (max-height: 464px){
          bottom: 0%;
        }
      }
    }
    .full-section-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
    .login-container {
      padding: 0;
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #fafafa;
      margin-left: 450px;
      @media (max-width: 992px){
        margin-left: 50%;
      }
      @media (max-width: 768px){
        margin-left: 0px;
        margin-top: 100px;
      }
      .content {
        padding: 40px;
        max-width: 620px;
        width: 100%;
        margin: 0 auto;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .form-group{
          position: relative;
          .fa-eye-slash{
            position: absolute;
            right: 15px;
            top: 30px;
            cursor: pointer;
          }
        }
        @media (max-width: 768px){
          max-width: 100%;
          padding: 20px;
        }
        h1 {
          font-size: 22px;
          font-weight: 800;
          line-height: normal;
          color: #171a1d;
          margin-bottom: 0;
          font-family: "Be Vietnam", sans-serif !important;
        }
      }
      .signup-detail {
        font-size: 15px;
        line-height: 1.6;
        color: #5e6267;
        margin-bottom: 50px;
      }
      .term-text {
        font-size: 14px;
        line-height: normal;
        color: #5e6267;
      }
      .last-row {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        margin: 35px 0;
      }
      .signup-text {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: normal;
        color: #5e6267;
        margin: 20px 0 0;
        button {
          color: #171a1d;
          text-decoration: underline;
          text-decoration: underline;
      background-color: unset;
      border: 0px;
        }
      }
    }
  }

  .ui.form .field .prompt.label{
    background: transparent!important;
    border: 0 !important;
    color: #dc3545 !important;
    padding: 0;
    font-weight: 400;
    margin-top: 0;
    &::before{
      content: none;
    }
  }
  
  .btn-gradient{
    background-image: linear-gradient(102deg, #3b1c5a, #374db1 162%) !important;
    color: #fff !important;
    text-transform: uppercase !important;
    display: flex !important;
    align-items: center;
    font-size: 13px !important;
    font-family: 'Be Vietnam', sans-serif !important;
    text-align: center;
    justify-content: center;
  }
