@import "./_variables";
.btn {
  width: auto;
  height: 50px;
  line-height: 50px;
  border-radius: 4px;
  font-size: 16px;
  padding: 0 15px;
  text-transform: none;
  &.btn-font-sm {
    font-size: 14px;
  }
  &.button-sm {
    min-width: 110px;
  }
  &.btn-primary {
    background: $primary-color !important;
    color: $white !important;
  }
  &.btn-secondary {
    background: $secondary-color !important;
    color: $primary-color !important;
  }
  &.btn-waiting {
    background-color: $orange-fade;
    color: $orange !important;
  }
  &.btn-application {
    background-color: $secondary-color;
    color: $black !important;
  }
  &.btn-grey {
    background-color: #5e6267 !important;
    color: $white !important;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 800;
    height: 45px;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
  }
}
