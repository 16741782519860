.rdtPicker {
  position: absolute;
  bottom: 50px;
  left: 40px;
}

.open-bottom{
  .rdtPicker {
    bottom: unset;
    left: 0;
  }
}

.rll {
  &-input {
    margin: 0 0 0 14px;
    width: 160px !important;
    height: 40px !important;
    border-radius: 0px !important;
    border: none;
    background-color: white !important;
    font-size: 13px !important;
  }
  &-date-input {
    & input {
      margin: 0 0 0 14px;
      width: 160px;
      height: 43.5px;
      border-radius: 0;
      font-size: 13px;
    }
  }
  &-error {
    position: absolute;
    margin-left: 15px;
    color: red;
    font-size: 1rem;
  }
  &-submit {
    &-1 {
      position: absolute;
      right: 45px;
      bottom: 14px;
      width: 190px;
      height: 45px;
      background-image: linear-gradient(102deg, #3b1c5a, #374db1 162%) !important;
      color: #fff !important;
    }
    &-2 {
      position: relative;
      left: 412px;
      bottom: 11px;
      width: 182px;
      height: 45px;
      background-image: linear-gradient(102deg, #3b1c5a, #374db1 162%) !important;
      color: #fff !important;
    }
  }
  &-cancel {
    &-1 {
      position: absolute;
      left: 45px;
      bottom: 14px;
      width: 190px;
      height: 45px;
      background-color: #5e6267 !important;
      color: #fff !important;
    }
    &-2 {
      display: block !important;
      position: relative;
      bottom: -34px;
      left: 214px;
      width: 182px;
      height: 45px;
      background-color: #5e6267 !important;
      color: #fff !important;
    }
  }
  &-check {
    &-label-1 {
      position: absolute;
      left: 72px;
      bottom: -26px;
    }
    &-label-2 {
      position: absolute;
      left: 60px;
      bottom: -17px;
    }
    &-input {
      margin-top: 14px;
      margin-left: 30px;
    }
  }
  &-additional {
    &-add {
      color: white;
      transform: scale(0.5, 0.5);
      position: absolute;
      left: -1px;
      align-items: center;
      &-background {
        border-radius: 50%;
        position: absolute;
        top: 23px;
        right: 2px;
        background-color: #5e6267;
        height: 14px;
        width: 14px;
      }
    }
    &-remove {
      position: absolute;
      color: white;
      transform: scale(0.5, 0.5);
      left: -1px;
      &-background {
        border-radius: 50%;
        position: absolute;
        top: 23px;
        right: 24px;
        background-color: #5e6267;
        height: 14px;
        width: 14px;
      }
    }
  }
  &-opener {
    color: #4C158A !important;
    position: absolute;
    right: 126px;
    &:hover {
      color: unset !important;
    }
  }
  &-price {
    text-align: center;
    width: 500px;
    height: 60px;
    margin: 26px auto 0 auto;
    font-size: 20px;
    background-color: rgba(128, 128, 128, 0.102);
    border: 3px dashed rgba(9, 71, 66, 0.2);
    &-bold {
      padding-top: 2px;
      font-weight: 900;
      font-size: 12px;
    }
  }
  &-proceed {
    display: block;
    width: 500px;
    height: 40px;
    bottom: 20px;
    border-radius: 3px;
    border: none;
    margin: 20px auto 0 auto;
    background-color: #5e6267;
    color: white;
    font-size: 13px;
    &:hover {
      cursor: pointer;
    }
  }
  &-benefits {
    &-h1 {
      font-weight: 900 !important;
      color: #3b1c5a;
      text-align: center;
      font-size: 18px;
      width: 349px;
      margin: 0 auto;
      height: 29px;
    }
    &-h2 {
      font-weight: bold;
      font-size: 13px;
      color: #5e6267;
      width: 500px;
      display: block;
      margin: 0 auto;
      padding-bottom: 16px;
      text-align: center;
      border-bottom: solid 1px rgba(94, 98, 103, 0.1);
    }
  }
  &-form {
    &-0 {
      height: 616px !important;
      width: 650px !important;
      border-radius: 3px;
    }
    &-1 {
      height: 246px !important;
      width: 650px !important;
      border-radius: 3px;
    }
    &-2 {
      height: 598px !important;
      width: 650px !important;
      border-radius: 3px;
    }
    &-h1{
      &-1 {
        font-size: 18px !important;
        font-weight: 800 !important;
        color: #171a1d;
        margin-left: 23px;
      }
      &-2 {
        font-size: 18px !important;
        font-weight: 800 !important;
        color: #171a1d;
        margin-left:16px;
      }
    }
    &-group {
      & p {
        font-size: 12px;
      }
    }
  }
  &-close {
    position: absolute;
    right: 20px;
    height: 30px;
    top: 20px;
    &:hover {
      cursor: pointer;
    }
  }
  &-logo {
    display: block;
    width: 170px;
    margin: 20px auto 14px auto;
  }
}

.p {
  &-1 {
    margin-left: 20px !important;
  }
  &-2 {
    margin-left: 10px !important;
  }
}

.circle {
  background-image: linear-gradient(133deg, #3b1c5a, #374db1 158%);
  height: 16px;
  width: 16px;
  border-radius: 50%;
  // position: absolute;
  // right: 50px;
  color: #f8f8f8;
  &-default-check {
    &-1 {
      height: 15px;
      width: 15px;
      position: absolute;
      left: 38px;
      bottom: -37px;
      opacity: 0;
      z-index: 10;
      cursor: pointer;
    }
    &-2 {
      position: absolute;
      height: 15px;
      width: 15px;
      left: 30px;
      bottom: -27px;
      opacity: 0;
      z-index: 10;
      cursor: pointer;
    }
  }
  &-checkmark {
    position: absolute;
    bottom: -16px;
    left: 38px;
    height: 14px;
    width: 14px;
    border-radius: 1px;
    background-image: linear-gradient(102deg, #3b1c5a, #374db1 162%);
  }
  &-unchecked {
    position: absolute;
    bottom: -6px;
    left: 30px;
    height: 14px;
    width: 14px;
    border-radius: 1px;
    border: 1px solid #3b1c5a;
    background-color: #f8f8f8;
  }
  &-checked {
    color: white;
    position: inherit;
    top: -0.5px;
    left: 3px;
    font-size: 10px;
  }
  &-1{
    position: absolute;
    left: 4.5px;
    bottom: 0.5px;
  }
  &-2{
    position: absolute;
    left: 3.8px;
    bottom: 0.5px;
  }
  &-3{
    position: absolute;
    left: 3.8px;
    bottom: 0.5px;
  }
  &.c1{
    position: absolute;
    left: 78px;
    top: 182px;
  }
  &.c2{
    position: absolute;
    left: 78px;
    top: 284px;
  }
  &.c3{
    position: absolute;
    left: 78px;
    bottom: 234px;
  }
}

.ol-p {
  width: 450px;
  line-height: 1.5;
  display: block;
  margin-left: 90px;
  margin-top: 10px;
  font-size: 12px;
}

.check-spacer {
  margin-bottom: 20px;
}

.input-label {
  position: absolute;
  left: 40px;
  top: 22px;
  font-size: 12px;
  z-index: 10;
}
