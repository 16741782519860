
@import "./_variables";
/* points cards */

.point-col {
  width: auto;
  p {
    width: 100%;
    color: $black;
    font-size: 12px;
  }
  h2 {
    color: $black;
    font-size: 30px;
    margin: 0;
    line-height: 30px;
  }
  span {
    width: 15px;
    height: 15px;
    display: inline-block;
    background: $primary-color;
    border-radius: 50%;
    text-align: center;
    line-height: 15px;
    color: $white;
    font-size: 10px;
    margin-left: 3px;
    position: relative;
    top: -7px;
  }
}
.card {
  height: auto !important;
  .card-title {
    font-size: 16px;
  }
  .card-title-small{
    margin-top: 26px;
    font-size: 13px;
    color: $theme-text-color;
    strong {
      font-family: $bold-font;
    }
  }
  .card-image-box {
    height: 197px;
    position: relative;
    .card-img-top {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &.property-card {
    .property-size {
      display: flex;
      .item {
        display: inline-flex;
        align-items: center;
        img {
          max-height: 14px;
          margin-right: 8px;
        }
        span {
          font-size: 15px;
        }
        &:after {
          content: '|';
          margin: 0 10px;
          color: rgba(2, 30, 64, 0.2);
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }
  }
  &.horizontal-card {
    .card-body {
      display: flex;
      padding: 0;
      .card-image {
        width: 200px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .property-details {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 60%;
      }
      .card-details {
        width: calc(100% - 160px);
        padding: 15px;
        .date-applied-wrapper {
          padding-top: 20px;
          width: 40%;
          text-align: right;
          flex: none;
          button {
            margin: 0 0 15px 0;
          }
          p {
            font-size: 12px;
          }
        }
        .property-size {
          padding: 10px 0;
        }
      }
    }
  }
  &.offer-card {
    .offer-items {
      padding: 0 10px;
      flex-grow: 1;
      .item {
        width: calc(100% / 3);
        padding: 10px;
        border-right: 1px solid $gray-fade;
        h3 {
          font-size: 20px;
          margin: 0;
          line-height: 25px;
        }
        p {
          font-size: 12px;
          margin: 0;
        }
      }
    }
    .gift-icon {
      flex: none;
    }
    .arrow-icon {
      flex: none;
      cursor: pointer;
    }
  }
}
.my-fav-property {
  .card {
    .card-title {
      &.title-bottom {
        position: absolute;
        top: -36px;
        right: 0;
        background: $theme-text-color;
        height: 40px;
        text-align: center;
        line-height: 40px;
        color: $white;
        width: auto;
        padding: 0 20px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
  }
  .property-details {
    padding-top: 20px;
  }
}
.btn-span {
  color: $primary-color;
}

.notification-btn{
  border-radius: 3px;
  background-color: #0000000d;
  padding: 7px;
  min-width: 35px;
  width: 35px;
  height: 35px;
  margin-left: 10px;
  cursor: pointer;
  &.alert{
    position: relative;
    &::before{
      content: '';
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: #ef2c2c;
      position: absolute;
      top: -4px;
      right: -4px;
      border: 1px solid #fff;
    }
  }
}
.notification-wrap{
  .notification-row{
    display: flex;
    padding: 25px 20px;
    background-color: $white;
    border-bottom: 1px solid #5e62671a;
    &:last-child{
      border: 0;
    }
    .icon-block{
      min-width: 35px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #3b1c5a1a;
      padding: 5px;
      margin-top: 4px;
    }
    .notification-text{
      font-size: 13px;
      line-height: 1.69;
      color: #171a1d;
      .red {
        color: red;
      }
    }
    .notification-time{
      font-size: 12px;
      color: #5e6267;
    }
  }
  .no-notification-wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    background-color: #fff;
    span{
      font-size: 20px;
      font-weight: bold;
      line-height: 1.35;
      text-align: center;
      color: #171a1d;
      margin-top: 20px;
    }
  }
}

// Modal
.basic-modal-wrap{
  max-width: 500px;
  padding: 45px 50px !important;
  background-color: #f8f8f8 !important;
  .content{
    background-color: #f8f8f8 !important;
    padding: 0 !important;
  }
  .modal-title{
    font-size: 20px;
    font-weight: bold;
    color: #171a1d;
    text-transform: initial;
  }
  .modal-subtitle{
    font-size: 16px;
    line-height: 1.5;
    color: #5e6267;
    margin-top: 9px;
    letter-spacing: normal;
  }
}
.modal-btn-row{
  margin: 0 -7px;
  .button{
    text-transform: uppercase;
    color: $white;
    min-height: 50px;
    border-radius: 3px;
    margin: 0 7px;
    font-size: 13px;
    font-weight: 800;
    flex-grow: 1;
  }
}
