.location-field{
    .ui.input{
        width: 100%;
        input{
            flex: unset;
        }
        .icon{
            background-color: #8d8e8e;
        }
    }
}
.autocomplete-dropdown-container{
    width: 100%;
    > div{
        padding: 5px 10px;
    }
}