

.modal-header{
    border-bottom: 0;
    background: #fafafa;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 40px 0 50px;
    .modal-title{
      font-size: 22px!important;
      font-weight: 800 !important;
      line-height: normal!important;
      color: #171a1d!important;
      margin-bottom: 5px!important;
      width: 100%!important;
      text-align: center!important;
      font-family: "Be Vietnam", sans-serif !important;
      @media (max-width: 768px){
        font-size: 18px;
      }
    }
    .modal-sutitle{
      font-size: 16px!important;
      line-height: normal!important;
      color: #5e6267!important;
      @media (max-width: 768px){
        font-size: 14px;
      }
    }
    .close{
      position: absolute;
      right: 8px;
      top: 7px;
      line-height: 0;
      margin: 0;
      padding: 20px;
    }
  }
  .modal-body{
      
    padding: 0 50px 50px;
    @media (max-width: 768px){
      padding: 0 20px 20px;
    }
    .modal-large-btn{
    //   display: flex!important;
      background-image: linear-gradient(
96deg, #3b1c5a, #374db1 163%) !important;
      width: 100%!important;
      border-radius: 3px!important;
      border: solid 1px transparent!important;
      background-color: rgba(#171a1d, 0.03)!important;
      text-align: center!important;
      text-transform: none!important;
      align-items: center!important;
      &:hover{
        border: solid 1px rgba(59, 28, 90, 0.15);
        background-color: #f0eef2;
        .text-heading,
        .text-des{
          color: #3b1c5a;
        }
        svg{
          path + path{
            fill: #3b1c5a;
          }
        }
      }
    }
    .image-section{
      margin-right: 15px;
    }
    .text-heading{
      font-size: 14px;
      font-weight: 500;
      color: #171a1d;
    }
    .text-des{
      font-size: 12px;
      font-weight: 500;
      color: #5e6267;
    }
  }
  .forgot-modal{
    background-color: #fafafa;
    .modal-header{
      padding: 50px 50px 30px;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 768px){
        padding: 20px;
      }
      .modal-title{
        text-align: left;
      }
      .modal-sutitle{
        margin-bottom: 0;
      }
    }
    .form-group{
      margin-bottom: 40px;
    }
    .modal-body{
      padding: 0 50px 55px;
      @media (max-width: 768px){
        padding: 0px 20px 20px
      }
    }
  }
  .success-modal{
    background-color: #fafafa;
    .modal-header{
      padding: 50px 50px 55px;
    }
    .success-image{
      margin-bottom: 30px;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      box-shadow: 0 5px 6px -4px #e5eced;
      background-color: #ffffff;
    }
    .modal-sutitle{
      font-size: 15px;
      line-height: 1.6;
      text-align: center;
      color: #5e6267;
      margin-bottom: 40px;
    }
  }
  
  .modal-large-btn{
    //   display: flex!important;
      background-image: linear-gradient(
96deg, #3b1c5a, #374db1 163%) !important;
      width: 100%!important;
      border-radius: 3px!important;
      border: solid 1px transparent!important;
      background-color: rgba(#171a1d, 0.03)!important;
      text-align: center!important;
      text-transform: none!important;
      align-items: center!important;
      font-size: 16px !important;
      font-weight: 400 !important;
      &:hover{
        border: solid 1px rgba(59, 28, 90, 0.15);
        background-color: #f0eef2;
        .text-heading,
        .text-des{
          color: #3b1c5a;
        }
        svg{
          path + path{
            fill: #3b1c5a;
          }
        }
      }
    }
