@import "./../../styles/quillpro/_quillpro-variables.scss";
@import "../../styles/quillpro/quillpro-mixins";
@import "./../../styles/variables.scss";


.background-check-modal__container {


  .modal-header {
    background: $qp-color-primary;
    span {
      color: white !important;
    }
  }

  .modal-title {
    color: white;
  }
  
  .modal-message {
    text-align: center;

    &--secondary {
      font-weight: bold;
      margin: 1rem 0;
    }
  }
}