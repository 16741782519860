@import "./../../styles/quillpro/_quillpro-variables.scss";
@import "./../../styles/variables.scss";

.property-modal-container {

  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    background: $qp-color-secondary;
    display: block;
  }
    
  a.carousel-control {
    &-prev,
    &-next {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 4.2rem;
      height: 4.2rem;
      background: $qp-color-darkblue;
    }

    &-prev {
      border-radius: $qp-border-radius-small;
      // border-top-left-radius: $qp-border-radius-large;
      // border-bottom-left-radius: $qp-border-radius-large;
    }
    &-next {
      border-radius: $qp-border-radius-small;
      // border-top-right-radius: $qp-border-radius-large;
      // border-bottom-right-radius: $qp-border-radius-large;
    }
  }

  .carousel-inner {
    position: relative;
    width: 100%;
    display: block;
    @include qp-border-radius();
    
    .inner-container {
      @include qp-border-radius();
      text-align: center;
      width: 100%;
      .carousel-img-container {
        width: 100%;
        height: 30rem;
        display: block;
        position: relative;
        
        
        img {
          width: 100% !important;
          height: 100% !important;
          object-fit: cover !important;
          object-position: center !important;
        }
      }
    }
  }
 

  .fa-map-marker {
    font-size: 2rem;
    color: red;
    transform: translateY(-100%);
  }

  .progress {
    margin-bottom: .3rem;
  }

  button.close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 5;
  }

  .modal-property-grid {
    .carm {
      display: none;
    }
    display: grid;
    gap: 1rem;
    grid-template-areas:
    "car car car car car car car car car car car car"
    "inf inf inf inf inf inf inf inf inf inf inf map"
    "poi poi poi poi poi poi poi poi poi poi poi but";
  
    .car {
      grid-area: car;
      width: 100%;
      .img {
        &-property {
          object-fit: cover;
          object-position: center;

        }
      }  
    }
    .inf {
      grid-area: inf;
      display: grid;
      grid-auto-rows: fit-content(110%);
      grid-template-areas: 
      "loc loc"
      "bbs bbs"
      "prc prc"
      "len len"
      "dsc dsc";

      .heading {
      font-size: 3rem;
      }
      .modal-points {
        border-radius: 4px;
        display: inline-block;
        color: $qp-color-white ;
        padding: .5rem 1.5rem;
        background-color: $qp-color-darkblue;
      }
      .loyalty-point-star{
        transform: translateY(-.01rem)
      }
      .duration-days{
        color: black;
      }
  
    }
    .len {
      grid-area: len;
    }
    .prc {
      grid-area: prc;
      justify-self: center;
      margin-bottom: 1rem;
    }
    .bbs {
      grid-area: bbs;
      justify-self: center;
    }
    .map {
      grid-area: map;
      justify-self: right;
      height: 300px;
      width: 300px;
    }
    .scr {
      grid-area: scr;
      text-align: center;
      margin-top: .5rem;
    }
    .loc {
      grid-area: loc;
      text-align: center;
    }
    .dsc {
      grid-area: dsc;
      border-top: solid $gray-fade 1px;
      border-bottom: solid 1px $gray-fade;
      padding-top: 1rem;
      overflow-y: auto;
      max-height: 400px;
    }
    .but {
      grid-area: but;
      width: 300px;
      margin-top: 2rem;
      justify-self: end;
    }
    .poi {
      align-self: end;
      grid-area: poi;
      vertical-align: bottom;
    }
    .ads {
      grid-area: ads;
  
    }
  }

  @media (max-width:992px){
    .car {
      display:none;
    }
    .modal-property-grid {

      padding: 0;
      margin: 0 auto;
      gap: .5rem;
      max-width: 400px;

      grid-template-areas:
      "car"
      "inf"
      "map"
      "poi"
      "but";
      
      .carm {
        grid-area: car;
        display: block;
        padding: 0;
        max-width: 400px;
        width: 100%;
        justify-self: center;

        .img {
          &-container {
            overflow: hidden;
          }
          
          &-property {
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        
      }
      .scr {

        .walkscore {
          // display: grid;
          // transform: translateX(-2rem);
          // width: 120%;
          // justify-items: start;
          // grid-template: auto / 1fr 1fr;
        }
      }
      .inf {
        width: auto;
        justify-self: center;

        grid-template-areas: 
        "loc"
        "bbs"
        "prc"
        "len"
        "dsc";

      }
      
      .map {
        grid-area: map;
        width: 100%;
        justify-self: center;
        margin-bottom: 3rem;
      }
      .loc {
        width: auto;
        grid-area: loc;
      }
      
      .but {
        grid-area: but;
        width: 100%;
        justify-self: start;
        margin-top: 0;
      }
      .poi {
        grid-area: poi;
        justify-self: start;
        width: 100%;
      }
      .dsc {
        margin-bottom: 1rem;
      }
      .ads {
        grid-area: ads;
        
      }
    }
  }
}



.select-time-buttons {
  .ui {
    &.basic {
      &.buttons {
        border-radius: 0;
        white-space: nowrap;
        &:not(:last-child) {
          border-right: 0;
        }
        &:first-child {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
        .ui {
          &.button {
            padding: 16.5px 7px;
          }
        }
      }
    }
  }
}

.schedule-date {
  & > div {
    & > div {
      margin: 0;
      padding: 0;
      border: 0;
    }
  }
  table {
    border-top: 0;
    thead {
      tr {
        &:first-child {
          th {
            &:hover {
              background: #343c49;
            }
          }
        }
      }
    }
  }
}