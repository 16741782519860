@import "./../../styles/quillpro/_quillpro-variables.scss";
@import "../../SiteShared.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

.x-hellosign-embedded__iframe {
  min-height: 400px;
  background-color: #f7f8f9;
  height: 84% !important;
  border: none;
  min-width: 80% !important;
  margin: 40px 0px 0px 200px !important;
}
.x-hellosign-embedded__modal-close {
  position: absolute;
  top: 0;
  right: 5px !important;
  height: 40px;
  width: 40px;
}
/* Cards */
.card,
.card .card-text {
  font-weight: 400;
}
.card .card-body h3,
.card .card-body h4 {
  font-weight: 300;
  font-size: 1rem;
}
.card {
  height: 100%;
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06) !important;
  @include qp-transition(box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1) !important);
  @include qp-border-radius();
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // color: $qp-color-primary;
  }
  > a > img,
  > a > img.img-fluid,
  > img,
  > img.img-fluid {
    // height: auto !important;
    width: 100% !important;
  }
  > a:first-child > img,
  > img:first-child {
    @include qp-border-radius-top-left();
    @include qp-border-radius-top-right();
  }
  > a:last-child > img,
  > img:last-child {
    @include qp-border-radius-bottom-left();
    @include qp-border-radius-bottom-right();
  }
  .card-header {
    position: relative;
    color: $qp-color-primary;
    background-color: transparent;
    text-transform: uppercase;
    font-size: $qp-active-font-size-button;
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    padding-top: 2.5rem;
    margin-left: $qp-default-padding;
    margin-right: $qp-default-padding;
    padding-left: 0;
    padding-right: 0;
    .header-btn-block {
      position: absolute;
      right: 0;
      top: 18px;
      .btn {
        margin: 0;
        padding-left: 0.95rem !important;
        padding-right: 0.95rem !important;
        // padding-right: .45rem;
        .batch-icon {
          color: $qp-color-white;
        }
      }
    }

    .see-tasks {
      a.btn.btn-primary {
        margin-right: 0.4em !important;
      }
    }
    .task-list-stats {
      font-size: 1rem;
      font-weight: 400;
      text-transform: none;
    }
    .progress {
      position: absolute;
      z-index: 1;
      bottom: -2px;
      left: -2.15rem;
      right: -2.15rem;
      width: auto;
      margin: 0;
      @include qp-border-radius(0 !important);
    }
  }
  .card-body {
    padding: 15px;
    font-weight: 400;
    p {
      margin: 0px;
    }
    .button-group {
      // background:red;
      margin-left: 50px;
    }
    .tile-left {
      position: absolute;
      // float: left;
      .batch-icon {
        color: $qp-color-white;
      }
    }
    .tile-right {
      // float: right;
      text-align: right;
      line-height: $qp-golden-ratio;
      .tile-number {
        font-size: 2rem;
        font-weight: 400;
      }
    }
    .card-chart {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      box-sizing: border-box;
      canvas {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .card-img {
    @include qp-border-radius();
  }
  .card-img-overlay {
    color: $qp-color-white !important;
    background-color: rgba(0, 0, 0, 0.3);
    @include qp-border-radius();
    * {
      color: $qp-color-white !important;
    }
  }
  .card-table {
    padding: 0 0 $qp-default-padding;
    table {
      thead,
      tfoot {
        tr {
          th:first-child {
            padding-left: $qp-default-padding;
          }
          th:last-child {
            padding-right: $qp-default-padding;
          }
        }
      }
      tbody {
        tr {
          > *:first-child {
            padding-left: $qp-default-padding;
          }
          > *:last-child {
            padding-right: $qp-default-padding;
          }
          a.btn.btn-primary {
            margin-right: 3px;
          }
        }
      }
    }
  }
  .card-media-list {
    height: 100%;
    padding: 0 0 2.083rem;
    .media {
      padding: 5px $qp-default-padding;
      .heading {
        font-weight: 500;
      }
    }
    .media:first-child {
      padding-top: 10px;
    }
    .media:last-child {
      padding-bottom: 10px;
    }
  }
  .card-user-profile {
    background-color: $qp-hover-highlight;
    padding: 0;
    .profile-page-left {
      position: relative;
      padding: $qp-default-padding;
    }
    .profile-page-center {
      padding: $qp-default-padding;
      background-color: $qp-color-white;
      .media-feed-control {
        margin-top: 1rem;
        padding: 10px 10px 4px;
        background-color: $qp-hover-highlight;
        a {
          opacity: 0.5;
          color: $qp-color-secondary-light-3;
          @include qp-transition(opacity 0.225s ease-in-out);
        }
        a:not(:last-child) {
          margin-right: 15px;
        }
        a:focus,
        a:hover {
          opacity: 1;
        }
        // > *:last-child{
        // 	margin-right: 15px;
        // }
      }
      > .comment-block {
        margin-bottom: 2.5rem;
        textarea {
          min-height: 5rem;
          margin-bottom: 0;
          opacity: 0.5;
          @include qp-border-radius-bottom-right(0);
          @include qp-border-radius-bottom-left(0);
          @include qp-transition(opacity 0.225s ease-in-out);
        }
        textarea:hover,
        textarea:focus {
          opacity: 1;
        }
        textarea + .media-feed-control {
          margin-top: 0;
          padding-top: 15px;
          .btn {
            opacity: 0.5 !important;
            margin-top: -6px;
          }
        }
        textarea:focus + .media-feed-control {
          .btn {
            opacity: 1 !important;
          }
        }
      }
      > ul {
        > li.media {
          padding-bottom: $qp-default-padding;
          margin-bottom: $qp-default-padding;
          border-bottom: 1px solid rgba(0, 0, 0, 0.09);
          .profile-picture {
            display: none;
          }
          .media-body {
            margin-left: 0 !important;
            .media-title {
              font-weight: 500;
              small {
                margin-left: 5px;
                text-transform: none;
                font-weight: 400;
                font-size: 90%;
              }
            }
            .media-body-reply-block {
              ul {
                .comment-reply-block {
                  .form-group {
                    textarea {
                      opacity: 0.5;
                      min-height: 3rem;
                      @include qp-transition(all 0.225s ease-in-out !important);
                    }
                    textarea:hover {
                      opacity: 1;
                    }
                    textarea:focus {
                      opacity: 1;
                      height: 10rem;
                    }
                    .comment-reply {
                      margin-top: -50px;
                      opacity: 0;
                      @include qp-transition(all 0.225s ease-in-out !important);
                    }
                    textarea:focus + .comment-reply {
                      margin-top: 0;
                      opacity: 1;
                    }
                  }
                }
              }
            }
          }
        }
        > li.media:last-child {
          border-bottom: 0;
        }
      }
    }
    .profile-page-block-outer {
      .profile-page-block {
        float: left;
        margin-bottom: 10px;
        margin-right: 5px;
      }
    }
  }
  .card-form-wizard {
    // padding-left: $qp-default-padding;
    .nav {
      display: table;
      width: 100%;
      > li {
        display: table-cell;
        float: none;
        vertical-align: top;
        > a {
          display: none;
          padding: 10px 4px;
          opacity: 0.5;
          > i.batch-icon,
          > span.wi,
          > span.glyphicon {
            float: left;
            color: #cccccc;
            margin-top: 5px;
          }
          > span.main-text {
            display: block;
            margin-left: 42px;
            > .h2 {
              display: block;
            }
            > small {
              display: block;
              color: #dddddd;
            }
          }
        }
        > a.active {
          opacity: 1;
          > i.batch-icon,
          > span.wi,
          > span.glyphicon {
            color: #555555;
          }
          > span.main-text {
            > .h2 {
              display: block;
            }
            > small {
              color: $qp-color-primary;
            }
          }
        }
        > a.show {
          display: block;
        }
      }
    }
    .nav-pills > li > a,
    .nav-pills > li > a:hover,
    .nav-pills > li > a:focus,
    .nav-pills > li.active > a,
    .nav-pills > li.active > a:hover,
    .nav-pills > li.active > a:focus {
      background: none transparent;
    }
    .tab-content {
      padding: $qp-default-padding;
    }
  }
  .card-footer {
    // padding-top: 2.5rem;
    padding-left: $qp-default-padding;
    padding-right: $qp-default-padding;
    padding-bottom: $qp-default-padding;
  }
}
.card:hover,
.card:focus {
  box-shadow: 0 9px 23px rgba(0, 0, 0, 0.18), 0 5px 5px rgba(0, 0, 0, 0.12) !important;
}
.card.lightbox {
  @include qp-transition(all 0.225s ease-in-out !important);
}
.card.lightbox:hover {
  transform: scale(1.05);
}

.card.card-xs {
  height: 95px;
  overflow: hidden;
}
.card.card-sm {
  height: 187px;
  overflow: hidden;
}
.card.card-md {
  height: 396px;
  overflow: hidden;
  .card-task-list {
    max-height: 300px;
  }
  .card-media-list {
    max-height: 330px;
  }
}
.card.card-lg {
  height: 536px;
  overflow: hidden;
  .card-task-list {
    max-height: 440px;
  }
  .card-media-list {
    max-height: 470px;
  }
}
.card {
  .batch-icon-xxl {
    opacity: 0.75;
    @include qp-transition(opacity 0.225s ease-in-out);
  }
}
.card:hover,
.card:focus {
  .batch-icon-xxl {
    opacity: 1;
  }
}

/* Responsiveness */

// Small devices (Not Mobile-first approach) (landscape phones, 576px and up)
@media (max-width: 575px) {
  .card {
    .card-body {
      .timeline {
        .timeline-items {
          .timeline-item {
            width: 100%;
            left: auto !important;
            margin-top: 100px !important;
            @include qp-border-radius-top-right();
          }
          .timeline-item::before {
            display: none !important;
          }
          .timeline-item::after {
            display: block !important;
            content: "";
            background: #5b5555 !important;
            width: 30px !important;
            height: 30px !important;
            position: absolute !important;
            top: -50px !important;
            @include qp-border-radius(100% !important);
            left: calc(50% - 12px) !important;
          }
        }
      }
    }
    .card .card-mailbox .mailbox-container tr .email-sender {
      display: none;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .card {
    .card-deck .card {
      height: auto;
    }
    .card-user-profile {
      @include qp-border-radius-bottom-left();
      @include qp-border-radius-bottom-right();
      .profile-page-center {
        > ul {
          > li.media {
            .profile-picture {
              display: block !important;
            }
            .media-body {
              margin-left: 1.5rem !important;
            }
          }
        }
      }
    }
  }
  .card-group .card {
    height: auto;
  }
  .card-columns {
    .card {
      width: 95%;
      margin-bottom: 3rem;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .card {
    .card-user-profile {
      .profile-page-left {
        float: left;
        width: 280px;
      }
      .profile-page-center {
        float: left;
        min-height: 810px;
        width: calc(100% - 280px);
        border-right: 1px solid rgba(0, 0, 0, 0.09);
        border-left: 1px solid rgba(0, 0, 0, 0.09);
        > ul {
          > li.media {
            .profile-picture {
              display: block !important;
            }
            .media-body {
              margin-left: 1.5rem !important;
            }
          }
        }
      }
    }
  }
}

#profit-loss {
  margin-top: -10px;
}

.favorite-properties {
  &-grid {
    display: grid;
    gap: 2rem;
    grid-template: auto / repeat(auto-fit, minmax(300px, 1fr));
  }

  &--title {
    margin-top: 3rem;
  }
}

@media (max-width: 1200px) {
  .applications-grid {
    display: grid;
    gap: 1rem;
    grid-template: auto / repeat(auto-fit, minmax(13rem, 1fr));
  }
}

.dashboard-container {
  .dashboard-grid {
    display: grid;
    gap: 2rem;
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "app house";
    .my-house {
      grid-area: house;
    }
    .applications-grid {
      grid-area: app;
    }
  }

  .dashboard-widgets {
    margin-bottom: 3rem;
    &__grid {
      display: grid;
      grid-template: auto / repeat(3, minmax(300px, 1fr));
      gap: 1rem;
    }

    &__title {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .heading {
        margin-bottom: 0px;
        justify-self: start;
      }
      button {
        width: auto;
        height: auto;
        padding-top: auto;
        justify-self: end;
      }
    }
  }

  .maintenance-card {
    opacity: 0.4;
    background: #d3d3d3;
    height: 8rem !important;
    position: relative;
    &__grid {
      display: grid;
      grid-template-areas:
        "i i d d"
        "l l t t";
    }

    &__icon {
      grid-area: i;
      position: absolute;
      color: $qp-color-info;
      font-size: 5rem;
      top: -1.4rem;
      left: 0;
    }

    &__date {
      grid-area: d;
      justify-self: end;
      padding: 0;
      margin: 0;
      position: absolute;
    }

    &__time {
      grid-area: t;
      font-size: 1.5rem !important;
      justify-self: end;
      padding: 0;
      margin-top: 1rem;
      @include qp-font($qp-font-family: "Montserrat");
    }

    &__link {
      grid-area: l;
      position: absolute;
      font-size: 1rem;
      right: 0;
      transform: translate(10px, -2rem);
    }
  }
  .rent-card {
    opacity: 0.4;
    background: #d3d3d3;
    height: 8rem !important;
    position: relative;
    &__grid {
      display: grid;
      grid-template-areas:
        "i i d d"
        "l l p p";
    }

    &__icon {
      grid-area: i;
      position: absolute;
      color: $qp-color-info;
      font-size: 6rem;
      top: -2rem;
      left: 0;
    }

    &__date {
      grid-area: d;
      justify-self: end;
      padding: 0;
      margin: 0;
      position: absolute;
    }

    &__price {
      grid-area: p;
      // font-size: 3rem !important;
      font-size: 1.5rem !important;
      justify-self: end;
      padding: 0;
      margin-top: 1rem;
      @include qp-font($qp-font-family: "Montserrat");
    }

    &__link {
      grid-area: l;
      position: absolute;
      font-size: 1rem;
      transform: translate(10px, -3rem);
    }
  }

  .no-results {
    position: relative;
    height: 100%;

    .card,
    .card-body {
      height: 90% !important;
    }
  }

  .trip-card {
    opacity: 0.4;
    background: #d3d3d3;
    height: 8rem !important;
    position: relative;
    &__grid {
      display: grid;
      grid-template-areas:
        "i i d d"
        "l l p p";
    }

    &__icon {
      grid-area: i;
      position: absolute;
      color: $qp-color-info;
      font-size: 6rem;
      top: -2rem;
      left: 0;
    }

    &__date {
      grid-area: d;
      justify-self: end;
      padding: 0;
      margin: 0;
      position: absolute;
    }

    &__place {
      grid-area: p;
      // font-size: 3rem !important;
      font-size: 1.5rem !important;
      justify-self: end;
      padding: 0;
      margin-top: 1rem;
      @include qp-font($qp-font-family: "Montserrat");
    }

    &__link {
      grid-area: l;
      position: absolute;
      font-size: 1rem;
      transform: translate(10px, -3rem);
    }
  }
}
@media (max-width: 1200px) {
  .dashboard-container {
    .dashboard-grid {
      grid-template-areas:
        "house house"
        "app app";
      .my-house {
        grid-area: house;
      }
      .applications-grid {
        grid-area: app;

        .applications-title {
          grid-column: 1 / -1;
        }
      }
    }
  }
}

@media (max-width: 370px) {
  .gift-icon {
    display: none;
  }
}

.event-card.ui.card {
  width: 100%;
  text-align: left;
  background-color: $accent-color-light;
  font-size: 1em;

  .event-delete {
    float: right;
  }

  &:hover {
    cursor: pointer;
  }
}
.event-delete {
  float: right;
}

.dashboard-page-container {
  display: flex;
  justify-content: space-between;
  padding-top: 0px;
  h4 {
    font-size: 18px;
    font-weight: normal;
    color: #3b1c5a;
  }
}
.dashboard-page-left {
  width: 100%;
}
.dashboard-page-right {
  width: 24.5%;
}

.dashboard-box {
  // padding: 13px 9px 18px 15px;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.52);
  background-color: #ffffff;
}

.dashboard-accordion {
  p {
    margin: 0;
  }
}

.ui {
  &.styled {
    &.accordion {
      &.dashboard-accordion {
        width: 100%;
        border: 0;
        box-shadow: none;
        border-radius: 0;
        background: unset;
        .header-title {
          display: flex;
          padding: 3px 14px;
          height: 100px;
          align-items: center;
          border: 0;
          &.active {
            .dashboard-accordion-price-area {
              display: none;
            }
          }
        }
      }
    }
  }
}
.dashboard-accordion {
  .dashboard-accordion-wrapper {
    border-radius: 3px;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0 11px 14px -10px #e5eced;
    .single-img {
      display: none;
      width: 100%;
      padding: 20px 20px 0px 20px;
      height: 418px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 3px;
      }
      .buttons-wrap {
        position: absolute;
        bottom: 10px;
        display: flex;
        right: 30px;
        .month-price {
          background-color: rgba(23, 26, 29, 0.9);
          width: 114px;
          height: 30px;
          font-size: 11px;
          font-weight: bold;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 30px;
          margin-left: 10px;
        }
      }
    }
    .active.title {
      .single-img {
        display: block;
      }
      .services-wrapper {
        display: flex;
      }
      .home-icon-page {
        display: none;
      }
      .header-title {
        height: auto;
      }
      .top-header-wrap {
        margin-bottom: 30px;
      }
    }
    .title {
      border-top: 0px !important;
      padding: 0px !important;
      .home-icon-page {
        height: 50px;
        width: 50px;
      }
    }
  }
}

.dashboard-accordion-head {
  margin-left: 10px;
  width: 50%;
  .property-address {
    font-size: 16px;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    display: flex;
    .rental-tag {
      border-radius: 15px;
      background-color: #5e6267;
      min-width: 125px;
      height: 20px;
      padding: 3px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      margin-left: 10px;
    }
  }
  .property-address-details {
    color: #5e6267;
    font-size: 13px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    display: flex;
    align-items: center;
    a {
      color: #3b1c5a;
    }
  }
}
.seprator {
  width: 5px;
  height: 5px;
  background-color: rgba(94, 98, 103, 0.3);
  border-radius: 50%;
  margin: 0px 10px;
}
.dashboard-accordion-price-area {
  width: calc(100% - 50%);
  text-align: right;
  button.lease-btn.ui.button {
    border-radius: 3px;
    background-color: #5e6267;
    padding: 0px !important;
    width: 120px;
    height: 30px;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    margin-right: 20px;
  }
  .show-price {
    color: #4c158a;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1;
  }
  .property-date,
  .property-small-detail {
    color: #555555;
    font-size: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    line-height: 1;
    margin-top: 5px;
  }
  .item {
    font-size: 13px;
    font-weight: bold;
    color: #3b1c5a;
  }
}

.price-tag {
  background: #dedede;
  border-radius: 30px;
  padding: 4px 13px;
  font-size: 11px;
  display: inline-block;
  color: #fff;
  font-weight: bold;
  vertical-align: middle;
  margin-left: 4px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  &.positive {
    background: #11ae7d;
  }
}

.more-data {
  text-align: center;
  margin-top: 20px;
  &.mt-35 {
    margin-top: 35px;
  }
  &.mb-30 {
    margin-bottom: 30px;
  }
  a {
    font-size: 10px;
    &.ft-12 {
      font-size: 12px;
    }
  }
}
.see-listing {
  text-align: right;
  a {
    font-size: 10px;
  }
}

.image-transactions-wrapper {
  display: flex;
}
.property-img {
  width: 100%;
  .three-image-wrapper {
    display: flex;
    height: 223px;
    .big-img {
      width: 70%;
    }
    .small-img-wrap {
      width: 30%;
      padding-left: 6px;
      .small-img {
        height: 108.5px;
        & + .small-img {
          margin-top: 6px;
        }
      }
    }
  }
  .single-img {
    height: 223px;
  }
}

.property-info-right {
  width: 33.4%;
  padding-left: 12px;
}

.transactions-list {
  min-height: 250px;
  .transactions-list-item {
    p {
      font-size: 16px;
      color: #4c158a;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
    }
    .show-date {
      display: block;
      font-size: 10px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      color: #555555;
      line-height: 1;
      margin-top: 3px;
    }
    & + .transactions-list-item {
      margin-top: 32px;
    }
    &.small {
      p {
        font-size: 12px;
        color: #000;
      }
    }
  }
}

.property-img {
  img {
    max-width: 100%;
    width: 100%;
    flex-wrap: wrap;
  }
}
.top-header-wrap {
  display: flex;
  flex-direction: row;
  width:100%;
  height: 100%;
  // margin-bottom: 30px;
}
.services-wrapper {
  display: none;
  flex: 0 0 50%;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-right: 20px;
  
  .services-col {
    width: 100%;
    display: flex;
    border-radius: 3px;
    height: 92px;
    margin-bottom: 10px;
    align-items: center;
    padding: 5px 15px;
    cursor: pointer;
    .action-btn {
      background-color: rgba(0, 0, 0, 0.1);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-left: auto;
    }
  }
  .icon-wrapper {
    width: 60px;
  }
  .services-details {
    width: 100%;
    padding-left: 12px;
    max-width: 100%;
    
    h2 {
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      font-weight: 900 !important;
      color: #ffffff;
      margin: 0;
    }
    p {
      
      font-family: "Roboto", sans-serif;
      font-size: 13px;
      font-weight: normal;
      line-height: 18px;
      margin-top: 2px;
      color: #ffffff;
    }
  }
}

.services-details-para {
  
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #555555;
  font-size: 8px;
  line-height: 1;
  
  margin: 5px 0;
  span {
    display: inline-block;
    vertical-align: 0;
    background: #ff6600;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 3px;
  }
}


  .mySlides {
    display: none;
  }


.requested-services {
  margin-top: 30px;
  .requested-services-head {
    font-weight: normal;
    color: #3b1c5a;
    font-size: 18px;
    margin-bottom: 20px;
  }
}
.carousel-dashboard {
  // padding:20px 20px 0px 20px;
  height: 100%;
    width: 100%;
    // position: absolute;
    // right: 0;
    // top: 0;
  .carousel-slider {
    height: 100%;
    width: 100%;
    // position: absolute;
    // right: 0;
    // top: 0;
    .slider-wrapper {
      height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    .slider {
      height: 100%;
      width: 100%;
      position: absolute;
      right: 0;
      top: 0;
      .slide {
      
        height: 100%;
        width: 100%;
        object-fit: contain;
     }
    }
    }
  }
}

.requested-services-row {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  &:last-child {
    border-bottom: 0px;
  }
  .requested-services-icon {
    width: 22px;
    margin-top: 1px;
  }
  .requested-services-info {
    font-family: "Roboto", sans-serif;
    width: 80%;
    .requested-services-title {
      color: #171a1d;
      font-size: 13px;
      font-weight: 500;
    }
    .requested-services-date {
      color: #555555;
      font-size: 9px;
    }
    .requested-services-point {
      color: #555555;
      font-size: 9px;
    }
    .time-rw {
      display: flex;
      align-items: center;
      p,
      span {
        font-size: 13px;
        font-weight: 500;
        color: #5e6267;
      }
    }
    .content-rw {
      p {
        color: #5e6267;
        line-height: 26px;
        margin-top: 10px;
      }
    }
  }
  .right-action-items {
    width: calc(100% - 80%);
    display: flex;
    justify-content: flex-end;
    .ui.dropdown {
      .dropdown.icon {
        display: none;
      }
      .menu {
        width: 160px;
        height: 145px;
        border-radius: 3px;
        box-shadow: 0 11px 14px -10px #e5eced;
        left: auto;
        right: 0px;
        padding: 20px 0px;
        .dropdown-item {
          height: 35px;
          font-size: 13px;
          font-weight: 500;
          color: #5e6267;
          display: flex;
          align-items: center;
          padding: 0px 20px;
          &:hover {
            color: #3b1c5a;
            background-color: rgba(59, 28, 90, 0.05);
            cursor: pointer;
          }
        }
      }
    }
    .status-txt {
      width: 80px;
      height: 25px;
      border-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
      &.assigned-txt {
        background-color: #11ae7d;
      }
      &.cancelled-txt {
        background-color: #ef2c2c;
      }
      &.resolved-txt {
        background-color: #171a1d;
      }
    }
  }
  .book-another-link {
    font-size: 11px;
    margin-left: auto;
    background-color: rgba(59, 28, 90, 0.05);
    width: 120px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-weight: bold;
    text-transform: uppercase;
    &:hover {
      color: unset;
    }
  }
  .more-action {
    background-color: rgba(59, 28, 90, 0.05);
    width: 25px;
    height: 25px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 3px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
      width: 21px;
      margin: 0px auto;
      height: auto;
    }
  }
}

.opacity-50 {
  opacity: 0.5;
}

.subhead-accordian {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 8px;
  color: #555555;
  margin: 13px 0 6px 6px;
}

.write-review-link {
  font-size: 10px;
}

.rental-history {
  .dashboard-accordion-head {
    .property-address {
      color: #555555;
    }
  }
  .dashboard-accordion-price-area {
    .show-price {
      color: #555555;
      font-size: 15px;
    }
  }
}

.add-section {
  display: flex;
  cursor: pointer;
  .add-section-col {
    width: calc(100% / 3);
    padding: 10px;
    text-align: center;
    &:first-child {
      width: 40%;
      img {
        display: inline-block;
        vertical-align: -5px;
        margin-left: 2px;
      }
    }
    &:nth-child(2) {
      border-left: 1px solid #eee;
      border-right: 1px solid #eee;
    }
  }
  .add-section-head {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    color: #000;
    margin: 0;
  }
  .add-section-text {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #555555;
    margin: 0;
  }
}

.tours-section {
  padding: 8px 11px 19px;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.52);
  background-color: #ffffff;
  margin: 13px 0;
  img {
    max-width: 100%;
  }
}

.tours-section-head {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #000;
  margin-bottom: 4px;
}

.tours-section-row {
  display: flex;
  flex-wrap: wrap;
  padding: 9px 0;
  & + .tours-section-row {
    border-top: 1px solid #c7c7c7;
  }
  p {
    font-size: 11px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #555555;
    margin: 0;
  }
  .tours-section-left-col {
    width: 131px;
    img {
      width: 131px;
      height: 131px;
      object-fit: cover;
    }
  }
  .tours-section-right-col {
    width: calc(100% - 131px);
    padding: 0 10px;
    text-align: center;
    .tours-date {
      font-size: 14px;
      color: #4c158a;
    }
    .tours-time-blue {
      background: #4c158a;
      color: #fff;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      margin: 15px auto 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 2px;
      p {
        color: #fff;
        font-size: 15px;
        line-height: 1;
      }
      span {
        font-size: 8px;
        display: block;
        line-height: 1;
      }
    }
  }
  .tours-map-link {
    text-align: right;
    a {
      font-size: 11px;
    }
  }
  .tours-address {
    margin: 6px 0 4px 0;
    width: 100%;
  }
}

.dashboard-applications-section {
  padding: 12px 10px 19px;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.52);
  background-color: #ffffff;
  max-height: 372px;
  overflow: auto;
}
.dashboard-applications-head {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #000;
  margin-bottom: 4px;
}
.dashboard-applications-row {
  padding: 12px 0 12px 0;
  & + .dashboard-applications-row {
    border-top: 1px solid #c7c7c7;
  }
  p {
    font-size: 11px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #555555;
    margin-bottom: 0;
  }
  .dashboard-applications-address,
  .dashboard-applications-last-update,
  .dashboard-applications-notofication {
    margin-bottom: 6px;
  }
  .dashboard-applications-notofication {
    font-style: italic;
  }
  .dashboard-applications-status {
    font-size: 13px;
    color: #4c158a;
    margin-bottom: 6px;
  }
}

.go-application-link {
  text-align: right;
  a {
    font-size: 11px;
  }
}

.show-property-info {
  display: inline-block;
  vertical-align: middle;
}

.only-pointer {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
  color: #4c158a;
}
a.pointer:not([href]):not([tabindex]),
a.pointer:not([href]):not([tabindex]):focus,
a.pointer:not([href]):not([tabindex]):hover {
  cursor: pointer;
  color: #4c158a;
}
.renter-dashboard-schedule {
  width: 100% !important;
  .schedule-rw {
    position: relative;
    .schedule-list {
      overflow-y: overlay;
      max-height: 350px;
    }
    .dashboard-cal {
      max-height: 350px;
    }
    .ui.celled.grid {
      margin: 0px;
      box-shadow: unset;
      .row {
        box-shadow: unset;
        .wide {
          border-bottom: 0px;
        }
      }
    }
    .ui.list {
      width: 100%;
      .item {
        width: 100%;
        margin-bottom: 16px;
        &:first-child {
          margin-top: 30px;
        }
        .header {
          border: none;
          color: #fff;
          background-color: #fff;
          height: 1px;
          background-image: linear-gradient(to right, #cac5c5 33%, #fff 0%);
          background-position: bottom;
          background-size: 5px 1px;
          background-repeat: repeat-x;
          padding: 3px 0px;
        }
        &.move-out-col {
          .description {
            background-color: #e2dcf2;
          }
        }
      }
      .content {
        float: right;
        width: calc(100% - 45px);
      }
      .description {
        background-color: #e0e0e0;
        width: 96%;
        height: 36px;
        display: flex !important;
        align-items: center;
        border-radius: 3px;
        padding: 6px;
        margin-top: -15px;
        margin: -17px auto 0px;
        font-size: 12px;
        font-weight: normal;
        color: #2e2e2e !important;
        .explore-icon {
          width: 20px;
          height: 20px;
          background-color: #fff;
          display: inline-block;
          border-radius: 3px;
          margin-right: 11px;
        }
      }
    }
  }
}

// renter dashboard


.renter-dashboard-content {
  margin-bottom: 50px;
  height: 300px;
  .ui.grid {
    margin: 0px;
    .left__content {
      // width: calc(100% - 210px) !important;
      padding: 0px 20px 0px 0px !important;

      .wrapper-content {
        background-image: linear-gradient(111deg, #a3a39f, #f3edea);
        border-radius: 3px;
        min-height: 250px;
        background-size: cover;
        padding: 30px 40px !important;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        h5 {
          color: #171a1d;
          font-size: 25px !important;
          font-weight: 800 !important;
          margin-bottom: 5px;
        }
        p {
          font-size: 16px;
          font-weight: normal;
          max-width: 250px;
          width: 100%;
          color: #43464b;
          margin-bottom: 20px;
          line-height: 1.6;
        }
        button {
          width: 130px;
          height: 35px;
          border-radius: 3px;
          background-color: rgba(23, 26, 29, 0.1);
          border: 0px;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
        }
      
        img {
          width: 300px;
          position: absolute;
          bottom: 0px;
          right: 50px;

        }
      }
      .wrapper-content-photo:hover{
        cursor:pointer;
      }
      .wrapper-content-photo {
        background-image: url("/assets/img/dashboard/bookingphoto.jpg");
        border-radius: 3px;
        min-height: 250px;
        background-position:50% 25%;
        background-size: stretch;
        background-repeat: no-repeat;
        padding: 30px 40px !important;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        .star{
          color:gold
        }
        h5 {
          color: #171a1d;
          font-size: 25px !important;
          font-weight: 800 !important;
          margin-bottom: 5px;
        }
        p {
          font-size: 16px;
          font-weight: normal;
          max-width: 250px;
          width: 100%;
          color: #43464b;
          margin-bottom: 20px;
          line-height: 1.6;
        }
        button {
          width: 130px;
          
          height: 35px;
          border-radius: 3px;
          background-color: rgba(23, 26, 29, 0.1);
          border: 0px;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
        }
        .DemoCenter {
          position: relative;
        }
        .tinyScreen{
          @media screen and (min-width: 1487px) {
            display:none !important;
          }
          color:black
        }
        img {
          position: absolute;
          bottom: 40%;
          left: 40%;
          transform: translate(-10%, -30%);
          width: 150px;
          height: 100px;
          @media screen and (max-width: 1487px) {
            display:none !important;
          }
        }
      }
    }
    .middle__content {
      padding-top: 0px !important;
      max-width: 238px;
      padding-bottom: 0 !important;

      .wrapper-content {
        background-image: linear-gradient(140deg, #f9f3ff, #eeeeff 100%);
        padding: 20px;
        border-radius: 3px;
        .application-btn {
          border: 1px dashed rgba(0, 0, 0, 0.15);
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          font-size: 10px;
          font-weight: 800;
          color: #ef2c2c;
          text-transform: uppercase;
          width: 139px;
          img {
            margin-right: 5px;
          }
        }
        .application-btn-success {
          border: 1px dashed rgba(0, 0, 0, 0.15);
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          font-size: 10px;
          font-weight: 800;
          color: green;
          text-transform: uppercase;
          width: 139px;
          img {
            margin-right: 5px;
          }
        }
        .app-status {
          border: 1px solid rgba(0, 0, 0, 0);
          width: 150px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          .button {
            padding-right: 10px !important;
            padding-left: 20px !important;
            
          }
          span {
            font-size: 10px;
            font-weight: 800;
            padding-left: 5px;
            color: black;
            text-transform: uppercase;
          }
        }
        h4 {
          margin-bottom: 8px;
        }
        h5 {
          font-size: 15px !important;
          font-weight: normal !important;
          max-width: 170px;
          margin-top: 0px;
          line-height: 24px;
          margin-bottom: 7px;
        }
        p {
          font-size: 11px;
          font-weight: normal;
          color: #5e6267;
          margin-bottom: 10px;
        }
        button {
          height: 30px;
          width: 100%;
          border-radius: 3px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 11px;
          
          &.view-app {
            background-color: rgba(94, 98, 103, 0.05);
            color: #5e6267;
            margin-top: 24px;
          }
          &.finish-app {
            background-color: #11ae7d;
            color: #ffffff;
          }
        }
        a {
          height: 30px;
          width: 100%;
          border-radius: 3px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 11px;
          &.view-app {
            background-color: rgba(94, 98, 103, 0.05);
            color: #5e6267;
            margin-bottom: 10px;
          }
          &.finish-app {
            background-color: #eceaf7;
            color: #70717a;
            
          }
        }
      }
      .slick-prev {
        transform: scale(0.7, 0.7);
        position: absolute;
        top: 4px;
        left: 18px;
        z-index: 10;
        right: 10px !important;
        border: 1px solid #3b1e5e;
        border-radius: 50%;
        background-color: #3b1e5e !important;
      }
      .slick-next {
        transform: scale(0.7, 0.7);
        position: absolute;
        top: 4px;
        right: 18px;
        border: 1px solid #3b1e5e;
        border-radius: 50%;
        background-color: #3b1e5e !important;
      }
    }

    .slider-h4 {
      margin-top: 6px;
    }

    .right__content {
      padding-top: 0px !important;
      max-width: 238px;
      padding-bottom: 0 !important;
      .slick-prev {
        transform: scale(0.7, 0.7);
        position: absolute;
        top: 4px;
        left: 18px;
        z-index: 10;
        right: 10px !important;
        border: 1px solid #3b1e5e;
        border-radius: 50%;
        background-color: #3b1e5e !important;
      }
      .slick-next {
        transform: scale(0.7, 0.7);
        position: absolute;
        top: 4px;
        right: 18px;
        border: 1px solid #3b1e5e;
        border-radius: 50%;
        background-color: #3b1e5e !important;
      }
      .wrapper-content {
        background-image: linear-gradient(140deg, #f9f3ff, #eeeeff 100%);
        padding: 20px;
        border-radius: 3px;
        height:258px;
        .application-btn {
          border: 1px dashed rgba(0, 0, 0, 0.15);
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          font-size: 10px;
          font-weight: 800;
          color: #ef2c2c;
          text-transform: uppercase;
          width: 139px;
          img {
            margin-right: 5px;
          }
        }
        .application-btn-success {
          border: 1px dashed rgba(0, 0, 0, 0.15);
          height: 31px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 3px;
          font-size: 10px;
          font-weight: 800;
          color: green;
          margin-top: 10px;
          text-transform: uppercase;
          width: 139px;
          img {
            margin-right: 5px;
          }
        }
        .app-status {
          border: 1px solid rgba(0, 0, 0, 0);
          width: 150px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          .button {
            padding-right: 10px !important;
            padding-left: 20px !important;
          }
          span {
            font-size: 10px;
            font-weight: 800;
            padding-left: 5px;
            color: black;
            text-transform: uppercase;
          }
        }
        h5 {
          font-size: 15px !important;
          font-weight: normal !important;
          max-width: 170px;
          margin-top: 25px;
          line-height: 24px;
          margin-bottom: 5px;
        }
        p {
          font-size: 11px;
          font-weight: normal;
          color: #5e6267;
          margin-bottom: 25px;
        }
        button {
          height: 30px;
          width: 100%;
          border-radius: 3px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 11px;
          &.view-app {
            background-color: rgba(94, 98, 103, 0.05);
            color: #5e6267;
            margin-top: 5px;
            margin-bottom: 5px;
          }
          &.finish-app {
            background-color: #11ae7d;
            color: #ffffff;
            margin-top:27%;
          }
        }
        a {
          height: 30px;
          width: 100%;
          border-radius: 3px;
          font-weight: bold;
          text-transform: uppercase;
          font-size: 11px;
          &.view-app {
            background-color: rgba(94, 98, 103, 0.05);
            color: #5e6267;
            margin-bottom: 10px;
          }
          &.finish-app {
            background-color: #eceaf7;
            color: #70717a;
            margin-top:2%;
          }
        }
      }
    }
  }
}

.dashboard-calendar-wrap {
  margin-top: 40px;
  h4 {
    font-size: 18px;
    font-weight: normal;
    color: #3b1c5a;
  }
  .renter-dashboard-schedule {
    box-shadow: 0 11px 14px -10px #e5eced !important;
  }
}

.blue-bg {
  background-color: #6b66ff;
}
.green-bg {
  background-color: #24c7af;
}
.orange-bg {
  background-color: #ff9821;
}
.purple-bg {
  background-color: #8567ba;
}
.ui.styled.accordion .content {
  padding: 0px 20px 40px 20px !important;
}
.event-detail {
  width: 300px;
  border-radius: 2px;
  box-shadow: 0 11px 14px -10px #e5eced;
  background-color: #ffffff;
  border: 1px solid #f5f5f5;
  position: absolute;
  right: 250px;
  top: -100px;
  z-index: 9000;
  .header {
    border-bottom: 1px solid rgba(94, 98, 103, 0.1);
    padding: 20px;
    h6 {
      font-size: 15px;
      font-weight: 600;
      color: #171a1d;
      margin-bottom: 0px;
    }
    span {
      font-size: 13px;
      font-weight: normal;
      color: rgba(23, 26, 29, 0.7);
      margin-top: -4px;
      display: block;
    }
  }
  .content {
    .list-rw {
      padding: 15px 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px solid rgba(94, 98, 103, 0.1);
      &.time-rw {
        flex-direction: row;
        .list-col {
          display: flex;
          flex-direction: column;
          width: 50%;
        }
      }
      label {
        color: #5e6267;
        font-size: 12px;
        font-weight: normal;
        margin-bottom: 0px;
      }
      span {
        color: #171a1d;
        font-size: 13px;
        font-weight: normal;
        line-height: 21px;
      }
      h6 {
        font-size: 12px;
        font-weight: normal;
        color: #555555;
        margin-bottom: 0px;
      }
    }
  }
  .footer-wrap {
    display: flex;
    height: 95px;
    align-items: center;
    flex-direction: column;
    padding: 0px 20px;
    button {
      height: 45px;
      color: #fff;
      border-radius: 3px;
      font-size: 13px;
      font-weight: 800;
      text-transform: uppercase;
    }
    .edit-btn {
      width: 100px;
      background-color: #6d6d6d;
      margin-right: 10px;
    }
    .reschedule-btn {
      background-image: linear-gradient(147deg, #3b1c5a, #374db1 155%);
      width: 150px;
    }
  }
}

.other-services {
  // h5 {
    font-size: 11px !important;
    font-weight: normal !important;
    margin-top: 0px;
    margin-bottom: 10px;
    color: #5e6267;
  // }
}
.avail-services {
  max-height:300px;
  overflow-y:auto;
  h4 {
    font-size: 16px;
    font-weight: 600 !important;
    color: #171a1d;
    margin-top: 30px !important;
    margin-bottom: 15px;
  }
 
  .services-card {
    border: 2px dashed #392469;
    background-color: rgba(23, 26, 29, 0.03);
    border-radius: 3px;
    padding: 20px;
    margin-bottom: 15px;
    display: flex;
    .left_wrap {
      width: 70%;
    }
    .right_wrap {
      display: flex;
      align-items: center;
      width: calc(100% - 70%);
      justify-content: flex-end;
      .price-tag-wrap {
        display: flex;
        flex-direction: column;
        margin-right: 13px;
      }
      .old-price {
        font-size: 12px;
        font-weight: normal;
        color: #5e6267;
        text-decoration: line-through;
      }
      .price-txt {
        font-size: 18px;
        font-weight: bold;
        margin-right: 16px;
        color: #171a1d;
      }
      .view-history {
        border-radius: 3px;
        background-color: rgba(20, 21, 23, 0.05);
        width: 95px;
        height: 30px;
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
        border: 0px;
        color: #5e6267;
        cursor: pointer;
      }
      .buy-now {
        width: 81px;
        height: 30px;
        border-radius: 3px;
        background-color: #5e6267;
        border: 0px;
        font-size: 11px;
        font-weight: bold;
        color: #ffffff;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
    h6 {
      font-size: 14px;
      font-weight: bold!important;
      color: #171a1d;
      margin-bottom: 0px;
    }
    p {
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      color: black;
      a {
        font-size: 12px;
        font-weight: normal;
        color: #3b1c5a;
        text-decoration: underline;
      }
    }
  }
  .other-services-card {
    border: 2px dashed rgba(9, 71, 66, 0.2);
    background-color: rgba(23, 26, 29, 0.03);
    border-radius: 3px;
    padding: 20px;
    margin-bottom: 15px;
    display: flex;
    .left_wrap {
      width: 70%;
    }
    .right_wrap {
      display: flex;
      align-items: center;
      width: calc(100% - 70%);
      justify-content: flex-end;
      .price-tag-wrap {
        display: flex;
        flex-direction: column;
        margin-right: 13px;
      }
      .old-price {
        font-size: 12px;
        font-weight: normal;
        color: #5e6267;
        text-decoration: line-through;
      }
      .price-txt {
        font-size: 18px;
        font-weight: bold;
        margin-right: 16px;
        color: #171a1d;
      }
      .view-history {
        border-radius: 3px;
        background-color: rgba(20, 21, 23, 0.05);
        width: 95px;
        height: 30px;
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
        border: 0px;
        color: #5e6267;
        cursor: pointer;
      }
      .buy-now {
        width: 81px;
        height: 30px;
        border-radius: 3px;
        background-color: #5e6267;
        border: 0px;
        font-size: 11px;
        font-weight: bold;
        color: #ffffff;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
    h6 {
      font-size: 14px;
      font-weight: bold!important;
      color: #171a1d;
      margin-bottom: 0px;
    }
    p {
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      color: #5e6267;
      a {
        font-size: 12px;
        font-weight: normal;
        color: #3b1c5a;
        text-decoration: underline;
      }
    }
  }
}
