@import "./../../styles/quillpro/_quillpro-variables.scss";
@import "../../styles/quillpro/quillpro-mixins";
@import "./../../styles/variables.scss";
@import "../../SiteShared.scss";

.single-property-details-container {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    color: $qp-color-primary;
    background: $qp-color-primary;
    display: block;

  }
  
  a.carousel-control {
    &-prev,
    &-next {
      position: absolute;
      height: 4rem;
      width: 4rem;
      top: 50%;
      transform: translateY(-50%);

      background: $qp-color-darkblue;
    }

    &-prev {
      border-radius: $qp-border-radius-small;
      // border-top-left-radius: $qp-border-radius-large;
      // border-bottom-left-radius: $qp-border-radius-large;
    }
    &-next {
      border-radius: $qp-border-radius-small;
      // border-top-right-radius: $qp-border-radius-large;
      // border-bottom-right-radius: $qp-border-radius-large;
    }
  }
  // .single-property-carousel {
  //   background:red;
  //   height:100% !important;
  // }

  .carousel-inner {
    position: relative;
    width: 100%;
    display: block;
    
    .inner-container {
      text-align: center;
      width: 100%;
    }
  }
 

  .fa-map-marker {
    font-size: 2rem;
    color: red;
    transform: translateY(-100%);
  }

  .card.quick-info {
    display: flex;
    align-self: start;
  }

  .single-property{
    &-grid {
    // display: grid;
    // gap: 4rem;
    // grid-template-columns: 1fr 1fr 20rem;
    // grid-template-areas:
    // "til til til"
    // "car car map"
    // "inf inf dat"
    // "bcc bcc bcc"
    // "con con con";
    }
    &-description {
      grid-area: bcc;
      border-top: solid $gray-fade 1px;
      border-bottom: solid 1px $gray-fade;
      padding-top: 1rem;
      overflow-y: auto;
      max-height: 400px;
      width:100%;
    }
    &-contact {
      grid-area:con;
      background: $qp-color-primary;
      color: $qp-color-white;
      border-bottom-left-radius: $qp-border-radius-large;
      border-bottom-right-radius: $qp-border-radius-large;

      &--title {

        h2 {
          color: $qp-color-white !important;
        }
      }

      &--info {
        display: flex;
        justify-content: space-between;
      }

      .item {
        
        &--value {
          width: 100%;
        }

        &--email {
          text-transform: lowercase;
        }
      }
    }
    &-title {
      grid-area: til;
      margin-top:1rem;
      margin-bottom: 30px;
      .card{
        display:grid;
        grid-template-columns: .1fr 1fr;
        
     .btn-primary{
       margin-top:0px;
       margin-bottom:0px;
       height:100%;
       margin-left:0px;
       border-top-right-radius: 0px;
       border-bottom-right-radius: 0px;
     }

     }
      .card-body {
        flex:1;
        background: $qp-color-primary;
        @include qp-border-radius;
        margin: 0;
      }
      
      .title-address-container {
        
        h4.heading-md {
          color: $qp-color-white !important;
          margin: 0;
        }
      }
    }
    &-carousel {
      grid-area: car;
      width: 100%;
      height:320px !important;
      .carousel-inner{
        @include qp-border-radius;
      }
      .img {
        &-property {
          object-fit: cover;
          object-position: center;

        }
      }  
    }
    &-info {
      grid-area: inf;
      display: grid;
      grid-auto-rows: fit-content(110%);
      grid-template-areas: 
      "bic bic"
      "dsc dsc";
      margin-top: 25px;

      .heading {
      font-size: 3rem;
      }
      .single-property-points {
        border-radius: 4px;
        display: inline-block;
        color: $qp-color-white ;
        padding: .5rem 1.5rem;
        background-color: $qp-color-darkblue;
      }
      .loyalty-point-star{
        transform: translateY(-.01rem)
      }
      .duration-days{
        color: black;
      }
      .basic-info-container {
        grid-area: bic;
        
        .basic-info-card {
          
          .card-body {
            padding: 0;
            min-height:260px;
            display: grid;
            grid-template: auto / minmax(10.9375rem, 1fr) 2fr;
            column-gap: 1rem;
          }
      
          .single-property-description {
            grid-column: 1 / -1;
            padding: 1rem;
            border-bottom: solid 2px $qp-color-primary;
          }

          .single-property-contact {
            grid-column: 1 / -1;
            padding: 1rem;
          }

          .basic-information-container,
          .ammenities-container {
            padding: 1rem;
            h4 {
              font-size: 1rem;
            }
          }
          
          .ammenities-container {

            display: grid;
            grid-template-columns: 1fr 1fr;

            &--title {
              grid-column: 1 / -1;
            }
         
            &--items {
              h3 {
                font-weight:bold !important;
              }
              i {
                margin-right: 1rem;
              }
            }
          }
        
          .basic-information-container {
    
            background-color: $qp-color-secondary;
            border-right: solid 2px $qp-color-primary;
            border-top-left-radius: $qp-border-radius-large;
            border-bottom-left-radius: $qp-border-radius-large;
    
            &--items {
              i {
                margin-right: 1rem;
              }
              .item {
                &--title {
                  font-weight: bold;
                }
    
                &--value {
                  text-transform: capitalize;
                }
              }
    
              .item-value {
                font-weight: normal !important;
                text-transform: capitalize;
              }
            }

          }
        }
        
    }
  
    }
    &-map {
      grid-area: map;
      justify-self: right;
      height: 320px;
      // width: 320px;
      padding: 20px;
      border-radius: 3px;
      box-shadow: 0 11px 14px -10px #e5eced;
      background-color: #fff;
      .map-title{
        font-size: 15px;
        font-weight: bold;
        color: #171a1d;
        margin-bottom: 20px;
        height: unset !important;
      }
      & > div{
        height: calc(100% - 40px) !important;
      }
    }
    &-data {
      grid-area: dat;
      text-align: center;
      display: grid;
      grid-template: fit-content(100%) / 1fr;
      gap: 1rem;
      position: relative;
      margin-bottom: 20px;
      margin-top: -40px;
      // height: 100%;
      border-radius: 4px;
      background-image: linear-gradient(131deg, #3b1c5a, #374db1 158%);
      padding: 25px 25px 10px;
    }

    &-apply {
      text-align: left;
      width: 100% !important;
      
      .card {
        overflow: hidden;
        width: 100%;
        background-color: transparent;
      }

      .card-body {
        padding: 0;
        // min-height: 388px;
        display: flex;
        flex-direction: column;
      }
      
      .apply {
      
        &--availability {
          padding: 1rem 0 1rem 1rem;
          margin-bottom: 1rem;
          background: $qp-color-secondary;
          border-bottom: solid 2px $qp-color-primary;
          text-align: center;
          
          &--title {
            font-weight: bold;
          }
          &--value {
            margin-left: 1rem;
          }
        }

        &--date-selection {
          padding: 0 1rem 0 1rem;
          input[type=date]::-webkit-inner-spin-button,
          input[type=date]::-webkit-outer-spin-button {
            -webkit-appearance: none;
          }
        }

        &--has-applied {
          text-align: center;
          margin-top: 2rem;
          padding: 1rem;
        }

        &--cancel {
          // height: 100%;
        }

        &--button {
          width: 100%;
          button {
            margin: 0 0 15px;
            width: 100%;
            background-color: #ffffff1a !important;
            font-size: 13px;
            font-weight: 800;
            text-transform: uppercase;
            &:first-child{
              background-color: #fff !important;
              color: #171a1d !important;
            }
          }

          .btn-cancel {
            position: absolute;
            bottom: 1rem;

          }
        }
      }

      .loader-container {
        height: 4.1rem;
      }
    }

  }
  
  
  @media (max-width: 992px) {
    .basic-info-card {
      .basic-information-container{
        border-right:none !important;
        grid-column:span 2;
      }
      .card-body {
        .ammenities-container{
  
          border-top: solid 2px $qp-color-primary;
          display:grid;
          grid-column: span 2;
          grid-template-rows:auto;   
        }
      }
    }
    
  }
   

  @media (max-width: 767px) {
    .single-property {
      &-grid {

        grid-template: auto / 1fr;
        grid-template-areas:
        "til"
        "car"
        "dat"
        "map"
        "inf"
        "bcc";
      }

      &-map {
        width: 100% !important;
      }

      &-apply {
        .apply--button {
          .btn {
            width: 93%;
          }
        }
      }
    }
  }
}


.lead-set{
  input{
    user-select: none;
    pointer-events:none;
    border:1px solid $accent-color !important;

  }
}

.property-information{
  .property-title{
      font-size: 25px;
      font-weight: bold;
      color: #171a1d;
  }
  .property-address{
      color: #5e6267;
      margin-top: 5px;
  }
  .property-info-row{
      display: flex;
      align-items: center;
      margin: 5px -5px 0;
      .property-info-col{
          font-size: 13px;
          font-weight: 500;
          color: #3b1c5a;
          margin: 0 5px 8px;
      }
      .dot-divider{
          width: 4px;
          height: 4px;
          border-radius: 50%;
          margin: 0 5px 5px;
          background-color: #6b66ff;
          opacity: 0.3;
      }
  }
  .property-description-row{
    margin-top: 20px;
    .property-description-title{
      font-size: 13px;
      color: #5e6267;
      margin-bottom: 3px;
    }
    .property-description{
      font-size: 14px;
      line-height: 2.14;
      color: #171a1d;
      letter-spacing: normal;
      pre {
        max-width:998px !important;
        overflow-wrap: break-word;
        word-wrap: break-word;
        white-space: pre-wrap;
      }
    }
  }
  .property-contact-row{
      display: flex;
      padding: 25px 30px;
      border-radius: 3px;
      background-color: #0000000d;
      margin-top: 25px;
      .property-contact-col{
          width: 50%;
          .contact-label{
              font-size: 12px;
              color: #5e6267;
          }
          .contact-info{
              font-weight: 500;
              color: #171a1d;
          }
      }
  }
}

.property-services-row{
  padding: 20px 30px;
  border-radius: 3px;
  box-shadow: 0 0px 5px 2px #e5eced;
  background-color: #fff;
  margin-top: 20px;
  .property-services-title{
      color: #5e6267;
      margin-bottom: 15px;
  }
  .services-list{
      .services-list-item{
          padding: 10px 20px 15px;
          border-radius: 3px;
          border: solid 1px #dedede;
          background-color: #f8f8f8;
          margin-bottom: 10px;
          .services-label{
              font-size: 13px;
              font-weight: bold;
              color: #171a1d;
              margin-bottom: 2px;
          }
          .services-description{
              font-size: 12px;
              line-height: 1.67;
              color: #5e6267;
          }
      }
  }
}

.property-detail-container{
  padding: 23px 16px !important;
  border-radius: 3px !important;
  border: 0 !important;
  box-shadow: 0 0px 5px 2px #e5eced !important;
  background-color: #fff !important;
  margin-top: 20px !important;
  h2{
      font-size: 15px;
      font-weight: bold;
      color: #171a1d;
      margin-bottom: 20px;
  }
  .basic-info-row{
      margin: 0 -8px 20px;
      display: flex;
      .basic-info-col{
          width: 33.33%;
          margin: 0 8px 15px;
          .info-description{
              color: #171a1d;
          }
      }
  }
  .info-label{
      font-size: 13px;
      color: #5e6267;
      margin-bottom: 5px;
  }
  .amenities-row{
      margin: 0 -5px;
      display: flex;
      flex-wrap: wrap;
      .amenities-col{
          margin: 5px;
          padding: 8px 20px 10px;
          border-radius: 3px;
          border: solid 1px #dedede;
          background-color: #f8f8f8;
      }
  }
}

.single-property-rewards{
  padding: 20px 0 38px;
  border-radius: 3px;
  box-shadow: 0 11px 14px -10px #e5eced;
  background-color: #fff;
  margin: 20px 0;
  .property-reward-title{
    font-size: 15px;
    font-weight: bold;
    color: #171a1d;
    text-align: center;
    padding: 0 10px 20px;
    border-bottom: solid 1px #7e7e7e1a;
  }
  .property-reward-body{
    padding: 40px 25px 15px;
    text-align: center;
    .reward-label{
      font-size: 25px;
      font-weight: 800;
      color: #3b1c5a;
      margin-bottom: 8px;
    }
    .reward-detail{
      font-size: 13px;
      line-height: 1.69;
      text-align: center;
      letter-spacing: normal;
      color: #5e6267;
    }
  }
}
