// lease modal

.lease-modal {
    height: 427px;
    border-radius: 6px !important;
    background-color: #f8f8f8 !important;
    will-change: unset !important;
    .semanticModal-request-header.header {
        background: unset !important;
        border: 0px !important;
        padding: 50px 50px 0px !important;
        position: relative;
        .header {
            text-align: left !important;
            font-size: 22px;
            font-weight: 800;
            color: #171a1d;
            font-family: "Be Vietnam", sans-serif !important;
            margin-bottom: 40px;
        }
        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
        }
    }
    .content {
        background: unset !important;
        padding: 0px 50px !important;
        .form-group {
            position: relative;
            margin-bottom: 20px;
            label {
                position: absolute;
                top: 15px;
                left: 20px;
                z-index: 9;
                color: #5e6267;
                margin-bottom: 0px;
                font-family: "Be Vietnam", sans-serif !important;
            }
            .ui {
                width: 100%;
                input {
                    border-radius: 3px !important;
                    box-shadow: 0 11px 14px -10px #e5eced !important;
                    background-color: #ffffff !important;
                    height: 75px;
                    border: 0px !important;
                    width: 100%;
                    box-sizing: border-box;
                    font-size: 14px;
                    font-weight: 500;
                    color: #171a1d;
                    font-family: "Be Vietnam", sans-serif !important;
                    padding: 15px 20px 0px;
                }
                &.search.selection {
                    min-height: auto;
                    min-width: 100%;
                    height: 75px;
                    border: 0px;
                    &.visible,
                    &.active {
                        z-index: 99;
                    }
                    input {
                        box-shadow: 0 11px 14px -10px #e5eced !important;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 15px 20px 0px !important;
                    }
                    .dropdown.icon {
                        height: 100%;
                        line-height: 50px;
                    }
                }
            }
            .text {
                top: 25px;
                font-size: 14px;
                font-weight: 500;
                padding: 0px 5px;
            }
        }
    }
    .actions.semanticModal-request-footer {
        background: unset !important;
        border: 0px !important;
        padding: 0px 50px !important;
        margin: 40px -10px 0px;
        display: flex;
        button {
            width: 50%;
            margin: 0px 10px !important;
            height: 45px;
            color: #ffffff;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 800;
            &.semanticModal-request-skip {
                background-color: #6d6d6d;
            }
            &.semanticModal-request-submit {
                background-image: linear-gradient(102deg, #3b1c5a, #374db1 162%);
            }
          
        }
    }
}

// add bank account modal
.ui.modals {
    .addbank-account-modal {
        height: 790px !important;
    }
    .addauto-account-modal {
        height: 600px !important;
    }
    .rdtPicker {
        margin-left:-60px;
    }
    .add-non-network{
        .rdtPicker {
            margin-left:0px !important;
        } 
    }
    .addbank-account-modal,
    .addauto-account-modal,
    .addcredit-card-modal {
        width: 495px !important;
        border-radius: 6px !important;
        background-color: #f8f8f8 !important;
        box-shadow: unset !important;
        height: 440px;
        overflow-y: auto;
        
        .header {
            font-size: 22px;
            font-weight: 800;
            border-bottom: 0px;
            background-color: unset;
            position: relative;
            padding: 30px 45px 20px;
            img {
                position: absolute;
                top: 18px;
                right: 20px;
                width: 8px;
                cursor: pointer;
            }
        }
        .content {
            background-color: unset;
            padding: 0px 45px;
            .form-rw {
                display: flex;
                margin: 10px -7px 0px;
                .form-group {
                    margin-left: 7px;
                    margin-right: 7px;
                }
            }
            .form-group {
                border-radius: 3px;
                box-shadow: 0 11px 14px -10px #e5eced;
                background-color: #ffffff;
                padding: 15px 20px;
                margin-bottom: 15px;
                height: 75px;
                input {
                    width: 100%;
                    margin: 0px;
                    border: 0px;
                    box-shadow: unset;
                    padding: 0px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #171a1d;
                }
                label {
                    font-size: 13px;
                    font-weight: 500;
                    font-family: "Be Vietnam", sans-serif;
                    color: #5e6267;
                    margin-bottom: 0px;
                }
                .selection.dropdown {
                    border: 0px;
                    width: 100%;
                    padding: 10px 0px;
                    .dropdown.icon {
                        right: 0px;
                    }
                }
            }
            .card-holder {
                border-radius: 6px;
                box-shadow: 0 11px 14px -10px #e5eced;
                background-color: #ffffff;
                padding: 20px;
                margin-bottom: 15px;
                background-image: url('/assets/img/card-bg.png');
                background-repeat: no-repeat;
                background-position: center right;
                position: relative;
                #number {
                    text-align: left;
                }
                
                img{
                    position: absolute;
                    right: 20px;
                    top: 20px;
                }
                input {
                    box-shadow: unset;
                    border: 0px;
                    border-bottom: 3px solid #e3e3e3;
                    border-radius: 0px;
                    margin: 0px 10px;
                    text-align: center;
                    padding-bottom: 0px;
                    font-size: 15px;
                    font-weight: normal;
                    background-color: unset!important;
                }
                label {
                    font-size: 11px;
                    font-weight: normal;
                    color: #5e6267;
                    text-transform: uppercase;
                    margin-bottom: 0px;
                    font-family: "Be Vietnam", sans-serif;
                }
                .footer-data {
                    margin-top: 47px;
                    display: flex;
                    justify-content: space-between;
                    .left-data,
                    .right-data {
                        width: 50%;
                        .form-control-select {
                            display: block;
                            // width: 100%;
                            padding: .375rem .75rem;
                            font-size: 1rem;
                            line-height: 1.5;
                            color: #495057;
                            background-color: #f9f9fb;
                            background-clip: padding-box;
                            border: 1px solid #ced4da;
                            border-radius: .25rem;
                            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                        }
                    }
                    .left-data {
                        input {
                            width: 195px;
                        }
                    }
                    .right-data {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        input {
                            width: 35px;
                        }
                    }
                    input {
                        text-align: left;
                        height: 20px;
                        padding: 0px;
                        margin: 0px;
                    }
                }
            }
        }
        .actions {
            border: 0px;
            padding: 30px 45px 0px;
            button {
                height: 45px;
                background-image: linear-gradient(97deg, #3b1c5a, #374db1 163%);
                width: 100%;
                margin: 0px;
                font-size: 13px;
                font-weight: 800;
                text-transform: uppercase;
                font-family: "Be Vietnam", sans-serif;
            }
        }
    }
    .addcredit-card-modal{
        .actions{
            padding: 20px 45px 0px;
        }
    }
}


.noHover {
    pointer-events: none;
}